import React, { useEffect, useState } from 'react';
import { View, Text, TextInput, StyleSheet, Linking, Picker, ActivityIndicator, ScrollView, Image } from 'react-native';
import { useForm, Controller } from 'react-hook-form';
import { Card } from 'react-native-elements';
import { RadioButton, Provider } from 'react-native-paper';
import {
  getOfferById,
  getPaymentFractions,
  getProductById
} from "../AdminPanel/Products/service/product-service";
import { getCEPData } from "./service/cep";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from '../../services/firebase';
import Button from "../../components/Button";
import { useCustomTheme } from "../../contexts/useCustomTheme";
import { useCustomImages } from "../../api/Brand";
import { useNavigation } from "@react-navigation/native";
import Icon from "react-native-vector-icons/Ionicons";
import InputMask from "react-input-mask";
import LogoLogin from './../../../assets/LogoLogin.png';
import THEME from './../../config/theme'
import ViewPortProvider from '../../hooks/ViewPortProvider';
import useViewport from '../../hooks/useViewport';

const Checkout = ({ route }) => {
  const navigation = useNavigation();
  const { control, handleSubmit, setValue, formState: { errors }, watch } = useForm();
  const { ProductId, OfferId } = route.params;
  const { customColor, customInfo } = useCustomTheme();
  const { data: logo, isLoading: isLoadingLogo } = useCustomImages('logoAuth');
  const [productData, setProductData] = useState(null);
  const paymentType = watch('payment.paymentType');
  const watchedZipCode = watch('address.zipCode');
  const [appInfo, setAppInfo] = useState()
  const [installments, setInstallments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [submitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!ProductId) return;

    const q = query(collection(firestore, "users"), where("isAdmin", "==", true));
    getDocs(q)
      .then(snapShot => {
        snapShot.forEach(doc => {
          const admin = doc.data();
          if (admin.appInformation) {
            setAppInfo(admin.appInformation);
          }
        });
      });

    if (OfferId) {
      getOfferById(ProductId, OfferId).then(res => {
        setProductData({ productId: ProductId, ...res.data() });
      })
        .finally(() => setIsLoading(false));
    } else {
      getProductById(ProductId).then(res => {
        setProductData({ productId: ProductId, ...res.data() });
      })
        .finally(() => setIsLoading(false));
    }
  }, [ProductId, OfferId]);

  useEffect(() => {
    if (productData && productData.type === 'plan') {
      setValue('payment.paymentFraction', 1);

      getPaymentFractions({ price: productData.price, paymentFractions: productData.paymentFractions })
        .then(res => {
          setInstallments(res.data.map(installment => ({
            number: installment.number,
            total: installment.total / 100
          })));
        })
    }

  }, [productData])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (watchedZipCode?.length === 8) {
        getCep(watchedZipCode);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [watchedZipCode]);

  const getCep = (zipCode) => {
    getCEPData(zipCode)
      .then(res => res.json())
      .then((json) => {
        if (json) {
          setValue('address.address', json.logradouro || '');
          setValue('address.neighborhood', json.bairro || '');
          setValue('address.city', json.localidade || '');
          setValue('address.state', json.uf || '');
          setValue('address.country', 'BR');
        }
      })
      .catch((error) => {
        console.error('Failed to fetch address:', error);
      });
  };

  const onSubmit = (data) => {
    setIsSubmitting(true);
    navigation.navigate('PostCheckoutProduct', { productData: productData, data: data });
  };

  const renderPickerItems = () => {
    return installments.map((installment) => (
      <Picker.Item key={installment.number}
        label={`${installment.number}x - ${(installment.total / installment.number).toFixed(2)} - R$${installment.total} total`}
        value={installment.number} />
    ));
  }

  const getFrequencyText = (frequency) => {
    if (frequency === '1') {
      return 'por mês';
    }

    return `a cada ${frequency} meses`;
  };

  const openCEPLink = () => {
    Linking.openURL('https://buscacepinter.correios.com.br/app/endereco/index.php#').catch((err) =>
      console.error('Failed to open URL:', err)
    );
  };

  const styles = StyleSheet.create({
    view: {
      flex: 1,
      padding: 40,
    },
    row: {
      flexDirection: 'row',
      flex: 1,
      padding: 40,
    },
    productOverview: {
      flex: 1,
      marginRight: 20,
    },
    formContainer: {
      flex: 2,
    },
    heading: {
      fontSize: 18,
      fontWeight: 'bold',
      marginVertical: 10,
    },
    input: {
      borderWidth: 1,
      borderColor: '#ccc',
      padding: 8,
      margin: 5,
      height: 40,
      borderRadius: 4,
      color: '#000',
    },
    sixthInput: {
      flex: 0.1,
    },
    phoneInput: {
      flex: 0.9,
    },
    halfInput: {
      flex: 0.5,
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    radioButtonGroup: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    errorText: {
      color: 'red',
      marginBottom: 10,
    },
    picker: {
      borderWidth: 1,
      borderColor: '#ccc',
      padding: 8,
      margin: 5,
      height: 40,
      borderRadius: 4,
      color: '#000',
      marginBottom: 10,
    },
    comprar: {
      width: "100%",
      margin: "10px"
    },
    overviewText: {
      color: customColor?.primaryColor,
      margin: "10px"
    },
  });

  const PurchaseSummary = () => {
    return (
      <View style={styles.productOverview}>
        {productData && (
          <Card containerStyle={{
            backgroundColor:
              customColor && customColor.background && customColor.background.app
                ? customColor.background.app
                : THEME.COLORS.BACKGROUND_ABOUT
          }}>
            {isLoadingLogo ? (
              <ActivityIndicator
                style={{
                  flex: 1,
                  justifyContent: 'center',
                }}
                color={
                  customColor && customColor.primary
                    ? customColor.primary
                    : THEME.COLORS.PRIMARY_800
                }
              />
            ) : (
              <Image
                source={logo ? logo.url : LogoLogin}
                style={{ margin: 10, height: 100 }}
                resizeMode="contain"
              />
            )}

            <Card.Title>{productData.name}</Card.Title>
            <Card.Divider style={{ marginVertical: "15px" }} />

            <Text style={[styles.overviewText,
            {
              fontSize: 20,
              fontWeight: 'bold'
            }]}>
              R$ {productData.price} {productData.type === 'plan' && getFrequencyText(productData.frequency)}
            </Text>
            <Text style={styles.overviewText}><strong>Tipo de
              Produto:</strong> {productData.type === 'plan' ? 'Assinatura' : 'Compra Única'}
            </Text>
            <Text style={styles.overviewText}><strong>Descrição:</strong> {productData.description}
            </Text>
            {productData.type === 'plan' && productData.trialDays &&
              <Text style={styles.overviewText}><strong>Teste
                grátis:</strong> {productData.trialDays} dias</Text>}
            {productData.expirationDate && <Text
              style={styles.overviewText}><strong>Validade:</strong> {productData.expirationDate} dias
              após a compra</Text>}
            <Card.Divider style={{ marginTop: "25px", marginBottom: "25px" }} />
            {appInfo?.supportContact && (<Text
              style={styles.overviewText}><strong>Suporte:</strong> {appInfo?.supportContact}
            </Text>)}
            {customInfo?.termsOfUseUrl &&
              <a href={customInfo?.termsOfUseUrl}><Text style={styles.overviewText}>Termos de uso e política de privacidade</Text></a>
            }
          </Card>
        )}
      </View>
    )
  }

  const UserRegistration = () => {
    return (
      <View style={styles.formContainer}>
        <Text style={styles.heading}>Identificação</Text>

        <Controller
          control={control}
          rules={{ required: 'Nome Completo é obrigatório' }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={styles.input}
              placeholder="Nome Completo"
              placeholderTextColor="#888"
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
            />
          )}
          name="customer.name"
        />
        {errors.customer?.name && <Text style={styles.errorText}>{errors.customer.name.message}</Text>}

        <Controller
          control={control}
          rules={{
            required: 'E-mail é obrigatório',
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: 'E-mail inválido',
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={styles.input}
              placeholder="E-mail"
              placeholderTextColor="#888"
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
            />
          )}
          name="customer.email"
        />
        {errors.customer?.email &&
          <Text style={styles.errorText}>{errors.customer.email.message}</Text>}
        <Controller
          control={control}
          rules={{
            required: 'Confirmação de e-mail é obrigatória',
            validate: value => value === watch('customer.email') || 'Os e-mails não correspondem',
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={styles.input}
              placeholder="Confirmar E-mail"
              placeholderTextColor="#888"
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
            />
          )}
          name="customer.emailConfirm"
        />
        {errors.customer?.emailConfirm &&
          <Text style={styles.errorText}>{errors.customer.emailConfirm.message}</Text>}
        <Controller
          control={control}
          rules={{
            required: 'CPF é obrigatório',
            pattern: { value: /^\d{11}$/, message: 'CPF inválido' },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={styles.input}
              placeholder="CPF"
              placeholderTextColor="#888"
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              maxLength={11}
            />
          )}
          name="customer.document"
        />
        {errors.customer?.document &&
          <Text style={styles.errorText}>{errors.customer.document.message}</Text>}

        <View style={styles.row}>
          <Controller
            control={control}
            rules={{ required: 'Código de Área é obrigatório' }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                style={[styles.input, styles.sixthInput]}
                placeholder="DDD"
                placeholderTextColor="#888"
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                maxLength={2}
              />
            )}
            name="customer.areaCode"
          />
          {errors.customer?.areaCode &&
            <Text style={styles.errorText}>{errors.customer.areaCode.message}</Text>}

          <Controller
            control={control}
            rules={{ required: 'Celular é obrigatório' }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                style={[styles.input, styles.phoneInput]}
                placeholder="Celular"
                placeholderTextColor="#888"
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                maxLength={9}
              />
            )}
            name="customer.phone"
          />
          {errors.customer?.phone &&
            <Text style={styles.errorText}>{errors.customer.phone.message}</Text>}
        </View>

        {productData?.type === 'plan' && (
          <>
            <Text style={styles.heading}>Endereço</Text>
            <Controller
              control={control}
              rules={{ required: 'CEP é obrigatório' }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={{ position: 'relative' }}>
                  <TextInput
                    style={styles.input}
                    placeholder="CEP"
                    placeholderTextColor="#888"
                    onBlur={onBlur}
                    onChangeText={(text) => {
                      onChange(text);
                    }}
                    value={value}
                  />
                  <Icon onPress={openCEPLink} name="help-outline" size={20} color="#888"
                    style={{
                      position: 'absolute',
                      right: 10,
                      top: '50%',
                      transform: [{ translateY: -10 }]
                    }} />
                </View>
              )}
              name="address.zipCode"
            />
            {errors.address?.zipCode &&
              <Text style={styles.errorText}>{errors.address.zipCode.message}</Text>}

            <Controller
              control={control}
              rules={{ required: 'Endereço é obrigatório' }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={styles.input}
                  placeholder="Endereço"
                  placeholderTextColor="#888"
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                />
              )}
              name="address.address"
            />
            {errors.address?.address &&
              <Text style={styles.errorText}>{errors.address.address.message}</Text>}

            <View style={styles.row}>
              <Controller
                control={control}
                rules={{ required: 'Número é obrigatório' }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    style={[styles.input, styles.halfInput]}
                    placeholder="Número"
                    placeholderTextColor="#888"
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                name="address.number"
              />
              {errors.address?.number &&
                <Text style={styles.errorText}>{errors.address.number.message}</Text>}

              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    style={[styles.input, styles.halfInput]}
                    placeholder="Complemento"
                    placeholderTextColor="#888"
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                name="address.complement"
              />
            </View>

            <View style={styles.row}>
              <Controller
                control={control}
                rules={{ required: 'Cidade é obrigatória' }}
                render={({ field }) => (
                  <TextInput
                    style={[styles.input, styles.halfInput]}
                    placeholder="Cidade"
                    placeholderTextColor="#888"
                    {...field}
                  />
                )}
                name="address.city"
              />
              {errors.address?.city &&
                <Text style={styles.errorText}>{errors.address.city.message}</Text>}

              <Controller
                control={control}
                rules={{ required: 'Estado é obrigatório' }}
                render={({ field }) => (
                  <TextInput
                    style={[styles.input, styles.halfInput]}
                    placeholder="Estado (UF)"
                    placeholderTextColor="#888"
                    {...field}
                    maxLength={2}
                  />
                )}
                name="address.state"
              />
              {errors.address?.state &&
                <Text style={styles.errorText}>{errors.address.state.message}</Text>}
            </View>

            <View style={styles.row}>
              <Controller
                control={control}
                rules={{ required: 'País é obrigatório' }}
                render={({ field }) => (
                  <TextInput
                    style={[styles.input, styles.halfInput]}
                    placeholder="País (ex: BR)"
                    placeholderTextColor="#888"
                    {...field}
                    maxLength={2}
                  />
                )}
                name="address.country"
              />
              {errors.address?.country &&
                <Text style={styles.errorText}>{errors.address.country.message}</Text>}

              <Controller
                control={control}
                rules={{ required: 'Bairro é obrigatório' }}
                render={({ field }) => (
                  <TextInput
                    style={[styles.input, styles.halfInput]}
                    placeholder="Bairro"
                    placeholderTextColor="#888"
                    {...field}
                  />
                )}
                name="address.neighborhood"
              />
              {errors.address?.neighborhood &&
                <Text style={styles.errorText}>{errors.address.neighborhood.message}</Text>}
            </View>

            <Text style={styles.heading}>Método de Pagamento</Text>
            <Controller
              control={control}
              rules={{ required: 'Selecione o método de pagamento' }}
              render={({ field: { onChange, value } }) => (
                <RadioButton.Group
                  onValueChange={onChange}
                  value={value}
                >
                  <View style={styles.radioButtonGroup}>
                    <RadioButton.Item label="Cartão de Crédito" value="credit_card" color="#888" />
                    <RadioButton.Item label="Cartão de Débito" value="debit_card" color="#888" />
                    {/*<RadioButton.Item label="Boleto" value="boleto" color='lightgray'/>*/}
                  </View>
                </RadioButton.Group>
              )}
              name="payment.paymentType"
            />
            {errors.payment?.paymentType &&
              <Text style={styles.errorText}>{errors.payment.paymentType.message}</Text>}

            {(paymentType === 'credit_card' || paymentType === 'debit_card') && (
              <>
                <Controller
                  control={control}
                  rules={{ required: 'Número do Cartão é obrigatório' }}
                  render={({ field }) => (
                    <TextInput
                      style={styles.input}
                      placeholder="Número do Cartão"
                      placeholderTextColor="#888"
                      {...field}
                    />
                  )}
                  name="payment.cardNumber"
                />
                {errors.payment?.cardNumber &&
                  <Text style={styles.errorText}>{errors.payment.cardNumber.message}</Text>}

                <Controller
                  control={control}
                  rules={{ required: 'Nome no Cartão é obrigatório' }}
                  render={({ field }) => (
                    <TextInput
                      style={styles.input}
                      placeholder="Nome no Cartão"
                      placeholderTextColor="#888"
                      {...field}
                    />
                  )}
                  name="payment.holderName"
                />
                {errors.payment?.holderName &&
                  <Text style={styles.errorText}>{errors.payment.holderName.message}</Text>}
                <View style={styles.row}>
                  <Controller
                    control={control}
                    rules={{ required: 'Data de Expiração é obrigatória' }}
                    render={({ field }) => (
                      <InputMask
                        mask="99/99"
                        value={field.value}
                        onChange={field.onChange}
                        placeholder="Data de Expiração MM/YY"
                        style={{
                          flex: 0.5,
                          background: "transparent",
                          borderWidth: 1,
                          borderColor: '#ccc',
                          padding: 8,
                          margin: 5,
                          height: 20,
                          borderRadius: 4,
                        }}
                      >
                        {inputProps => <input {...inputProps} />}
                      </InputMask>
                    )}
                    name="payment.expirationDate"
                  />
                  {errors.payment?.expirationDate && <Text
                    style={styles.errorText}>{errors.payment.expirationDate.message}</Text>}

                  <Controller
                    control={control}
                    rules={{ required: 'Código de Segurança (CVV) é obrigatório' }}
                    render={({ field }) => (
                      <TextInput
                        style={[styles.input, styles.halfInput]}
                        placeholder="CVV"
                        placeholderTextColor="#888"
                        {...field}
                      />
                    )}
                    name="payment.cvv"
                  />
                  {errors.payment?.cvv &&
                    <Text style={styles.errorText}>{errors.payment.cvv.message}</Text>}
                </View>
                <Controller
                  control={control}
                  rules={{ required: 'Selecione o número de parcelas' }}
                  render={({ field: { onChange, value } }) => (
                    <Picker
                      selectedValue={value}
                      onValueChange={onChange}
                      style={styles.picker}
                    >
                      {renderPickerItems()}
                    </Picker>
                  )}
                  name="payment.paymentFraction"
                />
                {errors.payment?.fractions &&
                  <Text style={styles.errorText}>{errors.payment.fractions.message}</Text>}
              </>
            )}
          </>
        )}

        <Button style={styles.comprar} title="Comprar" onPress={handleSubmit(onSubmit)} isLoading={submitting} />
      </View>
    )
  }

  const MobileOrDesktopComponent = () => {
    const { width } = useViewport();
    const breakpoint = 1080;
    return width < breakpoint ? (
      <View style={styles.view}>
        <UserRegistration />
        <PurchaseSummary />
      </View>
    ) : (
      <View style={styles.row}>
        <UserRegistration />
        <PurchaseSummary />
      </View>
    );
  };

  return (
    isLoading ? (
      <ActivityIndicator
        style={{
          flex: 1,
          justifyContent: 'center',
        }}
        color={
          customColor && customColor.primary
            ? customColor.primary
            : THEME.COLORS.PRIMARY_800
        }
        size="large"
      />
    ) : (
      <Provider>
        <ViewPortProvider>
          <ScrollView
            contentContainerStyle={{
              flexGrow: 1,
              paddingHorizontal: 15,
              paddingBottom: 60,
              backgroundColor: '#F3F3F3'
            }}
            style={{ width: '100%' }}
          >
            <MobileOrDesktopComponent />
          </ScrollView>
        </ViewPortProvider>
      </Provider>
    )
  );
};


export default Checkout;