import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { ClickClass } from "../pages/ClickClass";
import { ClickCard } from "../pages/ClickCard";
import { PDFView } from "../pages/PDFView";
import { About } from "../pages/About";
import { Main } from "../pages/Main";
import { ClickCourse } from "../pages/ClickCourse";
import { TermsofUse } from "../pages/TermsofUse";
import { Users } from "../pages/Users";
import { DrawerContent } from "../components/DrawerContent";
import THEME from '../config/theme';
import { Plans } from "../pages/Plans";
import { EditProfile } from "../pages/EditProfile";
import { SignUp } from "../pages/SignUp";
import { Products } from "../pages/AdminPanel/Products";
import { UsersAnalytics } from "../pages/Users/UserAnalytics";
import Constants from "expo-constants";
import Community from '../pages/Community';
import PostCommunity from '../pages/Community/PostCommunity';
import { CommunityProvider } from '../pages/Community/ContextCommunity/CommunityContext';
import {useRemoteConfigContext} from "../contexts/useRemoteConfigContext";
import ProductConfig from "../pages/AdminPanel/Products/ProdutConfig";
import Checkout from "../pages/Checkout/Checkout";
import { CustomLogin } from '../pages/CustomLogin';
import { useCustomTheme } from '../contexts/useCustomTheme';
import { MyAccount } from '../pages/MyAccount';
import { Brand } from '../pages/Brand';
import { hasOutsideAboutPage } from "../config/data";
import Octicons from '@expo/vector-icons/Octicons';
import PostCheckoutProduct from "../pages/Checkout/PostCheckoutProduct";

const Stack = createNativeStackNavigator();
const Drawer = createDrawerNavigator();
const { guruUserToken } = Constants.manifest.extra;

function DrawerRoutes() {
  const { customFont, customColor } = useCustomTheme();

  const config = useRemoteConfigContext();

  return (
    <Drawer.Navigator
      drawerContent={(props) => <DrawerContent {...props} />}
      screenOptions={{
        headerShown: false,
        drawerStatusBarAnimation: 'fade',

        drawerActiveBackgroundColor:
          customColor && customColor.text && customColor.text.app
            ? customColor.text.app
            : THEME.COLORS.DRAWER_SELECTED,

        drawerActiveTintColor: customColor && customColor.background && customColor.background.app
          ? customColor.background.app
          : THEME.COLORS.BACKGROUND_ABOUT,

        drawerInactiveTintColor:
          customColor && customColor.text && customColor.text.app
            ? customColor.text.app
            : THEME.COLORS.DRAWER_SELECTED,

        drawerStyle: {
          backgroundColor: customColor && customColor.background && customColor.background.app
            ? customColor.background.app
            : THEME.COLORS.BACKGROUND_ABOUT,
        },

        drawerLabelStyle: {
          fontSize: THEME.FONTSIZE.SMALL,
          fontFamily:
            customFont && customFont.fontFamilyText
              ? customFont.fontFamilyText
              : THEME.FONTFAMILY.REGULAR,
        },
      }}
    >
      <Drawer.Screen name="Conteudo" component={Main} />

      <Drawer.Screen name="Minha conta" component={MyAccount}
        options={{
          drawerIcon: ({ color }) => (
            <Octicons name="paintbrush" color={color} size={18} />
          ),
        }} />
      <Drawer.Screen name="Marca" component={Brand} options={{
        drawerIcon: ({ color}) => (
          <Octicons name="paintbrush" color={color} size={18} />
        ),
      }} />
      <Drawer.Screen name="Tela de login" component={CustomLogin} options={{
        drawerIcon: ({ color }) => (
          <Octicons name="paintbrush" color={color} size={18} />
        ),
      }} />

      {guruUserToken && guruUserToken.length > 0 || (config.paymentGateway === "PAGARME") ? (
        <Drawer.Screen name="Produtos" component={Products} />
      ) : (
        <Drawer.Screen name="Página de Vendas" component={About} />
      )}
      <Drawer.Screen name="Alunos" component={Users} />
      <Drawer.Screen name="Termos de Uso">
        {() => <TermsofUse isAdmin={true} />}
      </Drawer.Screen>
    </Drawer.Navigator>
  );
}
const CommunityScreen = () => (
  <CommunityProvider>
    <Community />
  </CommunityProvider>
);

const CommunityCommentsScreen = () => (
  <CommunityProvider>
    <PostCommunity />
  </CommunityProvider>
);

export function AdminRoutes() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Admin" component={DrawerRoutes} />
      <Stack.Screen name="Modulo" component={ClickCard} />
      <Stack.Screen name="Aula" component={ClickClass} />
      <Stack.Screen name="PDF" component={PDFView} />
      <Stack.Screen name="Produto" component={ClickCourse} />
      <Stack.Screen name="Página de Vendas" component={About} />
      {!hasOutsideAboutPage && <Stack.Screen name="Planos" component={Plans} />}
      <Stack.Screen name="Cadastro" component={SignUp} />
      <Stack.Screen name="ProductConfig" component={ProductConfig} />
      <Stack.Screen name="MeuPerfil">
        {props => <EditProfile {...props} userId={props.route.params.userId} editUserAccess={props.route.params.editUserAccess} />}
      </Stack.Screen>
      <Stack.Screen name="TermosdeUso" component={TermsofUse} />
      <Stack.Screen name="UsersAnalytics" component={UsersAnalytics} />
      <Stack.Screen name="Community" component={CommunityScreen} />
      <Stack.Screen name="Checkout" component={Checkout} />
      <Stack.Screen name="PostCheckoutProduct" component={PostCheckoutProduct} />
      <Stack.Screen
        name="CommunityComments"
        component={CommunityCommentsScreen}
      />
    </Stack.Navigator>
  );
}