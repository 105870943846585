import React from "react";
import { Controller } from "react-hook-form";
import { Picker, View } from "react-native";
import {
  Box,
  Container,
  ErrorText,
  FormContainer,
  InputWrapper,
  LabelPhoneNumber,
} from "./style";
import Header from "../../components/Header";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import ViewportProvider from "../../hooks/ViewPortProvider";
import { TextInputMask } from "react-native-masked-text";
import { useMyAccount } from "./useMyAccount";
import { ActivityIndicator } from "react-native-paper";
import THEME from "../../config/theme";
import Toast from "react-native-toast-message";
import toastConfig from "../../components/Toast/toast.config";
import { PhoneTextInput } from "./PhoneInput";
import { useCustomTheme } from "../../contexts/useCustomTheme";
import { StandardText } from "../../config/theme/globalStyles";
import { useRemoteConfigContext } from '../../contexts/useRemoteConfigContext';

export function MyAccount({ navigation }) {
  const {
    MobileOrDesktopForm,
    setPersonType,
    onSubmit,
    control,
    errors,
    handleSubmit,
    personTypeMemo,
    isLoadingUserInfo,
    isPendingUpdatedUserInfo,
    setValue,
    selectedCountry,
    setSelectedCountry,
    MobileOrDesktopContainer,
    isUrlValid,
    auth,
    termsOfUseUrl,
    setTermsOfUseUrl,
    salesPageUrl,
    setSalesPageUrl,
    formEmbed,
    setFormEmbed,
    androidUrl,
    setAndroidUrl,
    iosUrl,
    setIosUrl
  } = useMyAccount();

  const callingCode = (country) => {
    setValue("phoneDDI", country);
  };

  function handleSelectedCountry(country) {
    setSelectedCountry(country);
    callingCode(country.callingCode);
  }

  const user = auth.currentUser;

  const { customColor } = useCustomTheme();
  const config = useRemoteConfigContext();
  const hasForm = config.hasForm;

  return (
    <ViewportProvider>
      <Container>
        <Header navigation={navigation} isAdminPage />
        <MobileOrDesktopContainer>
          <Box>
            {isLoadingUserInfo ? (
              <View style={{ height: "30rem" }}>
                <ActivityIndicator
                  style={{
                    flex: 1,
                    backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                    justifyContent: "center",
                  }}
                  color={
                    customColor && customColor.primary
                      ? customColor.primary
                      : THEME.COLORS.PRIMARY_800
                  }
                />
              </View>
            ) : (
              <>
                <MobileOrDesktopForm>
                  <FormContainer>
                    <StandardText color='black' textAlign='left' margin='0.5rem 0rem'>
                      Configurações da minha conta:
                    </StandardText>
                    <InputWrapper>
                      <Controller
                        control={control}
                        name="Nome_Completo"
                        rules={{ required: "Nome completo é obrigatório" }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <>
                            <TextInput
                              color="black"
                              backgroundColor="white"
                              label="Nome completo"
                              placeholder="Digite seu nome completo"
                              onBlur={onBlur}
                              onChangeText={onChange}
                              value={value}
                              error={errors.name}
                            />
                            {errors.name && (
                              <ErrorText>{errors.name.message}</ErrorText>
                            )}
                          </>
                        )}
                      />
                    </InputWrapper>

                    <InputWrapper>
                      <TextInput
                        color="black"
                        backgroundColor="white"
                        label="E-mail"
                        value={user.email}
                        editable={false}
                      />
                    </InputWrapper>

                    <View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {personTypeMemo === "Pessoa Física" && (
                          <InputWrapper style={{ width: "100%" }}>
                            <Controller
                              control={control}
                              name="CPF"
                              rules={{
                                required: "CPF é obrigatório",
                                minLength: 14,
                              }}
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <TextInput
                                  color="black"
                                  backgroundColor="white"
                                  label="CPF"
                                  placeholder="Digite seu CPF"
                                  onBlur={onBlur}
                                  value={value}
                                  onChangeText={onChange}
                                  error={errors.CPF}
                                  render={(props) => (
                                    <TextInputMask {...props} type={"cpf"} />
                                  )}
                                />
                              )}
                            />
                          </InputWrapper>
                        )}
                        {personTypeMemo === "Pessoa Jurídica" && (
                          <InputWrapper style={{ width: "100%" }}>
                            <Controller
                              control={control}
                              name="CNPJ"
                              rules={{
                                required: "CNPJ é obrigatório",
                                minLength: 18,
                              }}
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <TextInput
                                  color="black"
                                  backgroundColor="white"
                                  label="CNPJ"
                                  placeholder="Digite seu CNPJ"
                                  onBlur={onBlur}
                                  onChangeText={onChange}
                                  value={value}
                                  error={errors.CNPJ}
                                  render={(props) => (
                                    <TextInputMask {...props} type={"cnpj"} />
                                  )}
                                />
                              )}
                            />
                          </InputWrapper>
                        )}
                        <Picker
                          selectedValue={personTypeMemo}
                          style={{
                            height: 40,
                            position: "absolute",
                            border: "none",
                            right: 2,
                          }}
                          onValueChange={(itemValue) =>
                            setPersonType(itemValue)
                          }
                        >
                          <Picker.Item
                            label="Pessoa Física"
                            value="Pessoa Física"
                          />
                          <Picker.Item
                            label="Pessoa Jurídica"
                            value="Pessoa Jurídica"
                          />
                        </Picker>
                      </View>

                      {errors.CPF && (
                        <ErrorText style={{ marginTop: 0 }}>
                          {errors.CPF.type === "minLength"
                            ? "Mínimo de caracteres não atingido"
                            : errors.CPF.message}
                        </ErrorText>
                      )}
                      {errors.CNPJ && (
                        <ErrorText style={{ marginTop: 0 }}>
                          {errors.CNPJ.type === "minLength"
                            ? "Mínimo de caracteres não atingido"
                            : errors.CNPJ.message}
                        </ErrorText>
                      )}
                    </View>

                    <InputWrapper>
                      <Controller
                        control={control}
                        name="appInformation.appName"
                        rules={{ required: "Nome da empresa é obrigatório" }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <>
                            <TextInput
                              color="black"
                              backgroundColor="white"
                              label="Nome da empresa"
                              placeholder="Digite o nome da empresa"
                              onBlur={onBlur}
                              error={errors.appInformation?.appName}
                              onChangeText={onChange}
                              value={value}
                            />
                            {errors.appInformation?.appName && (
                              <ErrorText>
                                {errors.appInformation?.appName.message}
                              </ErrorText>
                            )}
                          </>
                        )}
                      />
                    </InputWrapper>

                    <InputWrapper>
                      <Controller
                        control={control}
                        name="appInformation.supportContact"
                        rules={{ required: "Contato para suporte é obrigatório" }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <>
                            <TextInput
                              color="black"
                              backgroundColor="white"
                              label="Contato de suporte para os alunos"
                              placeholder="Digite o contato de suporte (email ou telefone)"
                              onBlur={onBlur}
                              error={errors.appInformation?.supportContact}
                              onChangeText={onChange}
                              value={value}
                            />
                            {errors.appInformation?.supportContact && (
                              <ErrorText>
                                {errors.appInformation?.supportContact.message}
                              </ErrorText>
                            )}
                          </>
                        )}
                      />
                    </InputWrapper>

                    <InputWrapper
                      style={{ position: "relative", marginTop: "8px" }}
                    >
                      <Controller
                        control={control}
                        name="telephone"
                        rules={{
                          required:
                            "Número de telefone para contato é obrigatório",
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <>
                            <LabelPhoneNumber>
                              N° de telefone para contato
                            </LabelPhoneNumber>
                            <PhoneTextInput
                              onBlur={onBlur}
                              value={value}
                              onChange={onChange}
                              errors={errors}
                              selectedCountry={selectedCountry}
                              handleSelectedCountry={handleSelectedCountry}
                            />
                            {errors.telephone && (
                              <ErrorText>{errors.telephone.message}</ErrorText>
                            )}
                          </>
                        )}
                      />
                    </InputWrapper>
                  </FormContainer>

                  <FormContainer>
                    <StandardText color='black' textAlign='left' margin='0.5rem 0rem'>
                      Link dos termos de uso:
                    </StandardText>

                    <InputWrapper>
                      <TextInput
                        color="black"
                        backgroundColor="white"
                        label="URL dos seus termos de uso"
                        placeholder="https://exemplo.com.br"
                        onChangeText={(text) => setTermsOfUseUrl(text)}
                        value={termsOfUseUrl}
                      />
                    </InputWrapper>

                    <StandardText color='black' textAlign='left' margin='0.5rem 0rem'>
                      Link da página de vendas:
                    </StandardText>

                    <InputWrapper>
                      <TextInput
                        color="black"
                        backgroundColor="white"
                        label="URL da sua página de vendas"
                        placeholder="https://exemplo.com.br"
                        onChangeText={(text) => setSalesPageUrl(text)}
                        value={salesPageUrl}
                      />
                    </InputWrapper>

                    <StandardText color='black' textAlign='left' margin='0.5rem 0rem'>
                      Links dos Aplicativos:
                    </StandardText>

                    <InputWrapper>
                      <Controller
                        control={control}
                        name="appInformation.IOSUrl"
                        rules={{
                          validate: (value) => {
                            if (!value) {
                              return true;
                            }
                            // Caso contrário, verifica se a URL é válida
                            return isUrlValid(value) || "Insira uma URL válida";
                          },
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <>
                            <TextInput
                              color="black"
                              backgroundColor="white"
                              label="URL do app na Apple"
                              placeholder="Digite a URL"
                              onBlur={onBlur}
                              onChangeText={(text) => {
                                onChange(text);
                                setIosUrl(text);
                              }}
                              value={value}
                              error={errors.appInformation?.IOSUrl}
                            />
                            {errors.appInformation?.IOSUrl && (
                              <ErrorText>
                                {errors.appInformation.IOSUrl.message}
                              </ErrorText>
                            )}
                          </>
                        )}
                      />
                    </InputWrapper>

                    <InputWrapper>
                      <Controller
                        control={control}
                        name="appInformation.androidUrl"
                        rules={{
                          validate: (value) => {
                            if (!value) {
                              return true;
                            }
                            // Caso contrário, verifica se a URL é válida
                            return isUrlValid(value) || "Insira uma URL válida";
                          },
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <>
                            <TextInput
                              color="black"
                              backgroundColor="white"
                              label="URL do app na PlayStore"
                              placeholder="Digite a URL do app na Play Store"
                              onBlur={onBlur}
                              onChangeText={(text) => {
                                onChange(text);
                                setAndroidUrl(text);
                              }}
                              value={value}
                              error={errors.appInformation?.androidUrl}
                            />
                            {errors.appInformation?.androidUrl && (
                              <ErrorText>
                                {errors.appInformation.androidUrl.message}
                              </ErrorText>
                            )}
                          </>
                        )}
                      />
                    </InputWrapper>

                    {hasForm &&
                      <>
                        <StandardText color='black' textAlign='left' margin='0.5rem 0rem'>
                          Link do formulário Google:
                        </StandardText>

                        <InputWrapper>
                          <TextInput
                            color="black"
                            backgroundColor="white"
                            label="URL do formulário do Google"
                            placeholder="https://docs.google.com/forms/"
                            onChangeText={(text) => setFormEmbed(text)}
                            value={formEmbed}
                          />
                        </InputWrapper>
                      </>
                    }
                  </FormContainer>
                </MobileOrDesktopForm>
                <Button
                  title="Salvar"
                  onPress={handleSubmit(onSubmit)}
                  isLoading={isPendingUpdatedUserInfo}
                  style={{ cursor: "pointer", alignSelf: "center" }}
                />
              </>
            )}
          </Box>
        </MobileOrDesktopContainer>
      </Container>
      <Toast config={toastConfig} />
    </ViewportProvider>
  );
}
