import React, { useCallback, useEffect } from 'react';
import { Dimensions, Picker, StyleSheet, Text, View } from 'react-native';
import TextInput from "../../../components/TextInput";
import { ViewButton, ViewTextInput } from "./style";
import Button from "../../../components/Button";
import { useForm, Controller } from 'react-hook-form';
import { TooltipCustom } from "../../../components/Tooltip";
import CurrencyInput from "react-native-currency-input";

const screenWidth = Dimensions.get("window").width;


const CreateProductGenericForm = ({ onClose, onCreate, saveHandler, initialProduct }) => {
  const [loadingSave, setLoadingSave] = React.useState(false);

  const { control, handleSubmit, setValue, watch, formState: { errors }, setError, clearErrors } = useForm({
    defaultValues: {
      name: initialProduct?.name || null,
      description: initialProduct?.description || null,
      type: initialProduct?.type || 'product',
      maxFrequency: 0,
      hasExpirationDate: true,
      archived: false,
      paymentFractions: 1,
      trialDays: 0,
    },
  });

  const watchType = watch('type');
  const watchFrequency = watch('frequency');
  const watchPaymentFractions = watch('paymentFractions');

  const onSubmit = useCallback(async (formData) => {
    try {
      setLoadingSave(true);
      saveHandler(formData)
        .finally(() => {
          onClose()
          setLoadingSave(false)
        });
    } catch (error) {
      onClose();
    }
  }, [onCreate, onClose]);

  useEffect(() => {
    if (watchType === 'plan') {
      setValue('frequency', "1")
      setValue('maxFrequency', "0")
      setValue('trialDays', "0")
    }
  }, [watchType]);

  useEffect(() => {
    if (!watchFrequency || !watchPaymentFractions) {
      return;
    }

    if (Number(watchFrequency) < watchPaymentFractions) {
      setValue('paymentFractions', watchFrequency);
    }

  }, [watchFrequency])

  const getValidationRules = (field, message) => {
    const rules = { required: `${message} é obrigatório` };

    if (field === 'frequency' || field === 'trialDays') {
      if (watchType === 'plan') {
        return rules;
      }
    }

    if (field === 'expirationDate' && watchType === 'plan') {
      return { required: false };
    }

    return rules;
  };

  const renderPaymentFractionItems = () => {
    let maxFractions = watchType !== 'plan' ? 12 : Math.min(12, Number(watchFrequency));

    return Array.from({ length: maxFractions }, (_, i) => (
      <Picker.Item key={i + 1} label={`${i + 1}`} value={`${i + 1}`} />
    ));
  };

  return (
    <View style={styles.modalView}>
      <View style={{ width: '100%' }}>
        {!initialProduct && (
          <View>
            <View style={styles.row}>
              <ViewTextInput style={styles.halfWidth}>
                <Text>Nome:<TooltipCustom text="Nome do produto. Máximo de 13 caracteres." /></Text>
                <Controller
                  name="name"
                  control={control}
                  rules={getValidationRules('name', 'Nome do Produto')}
                  render={({ field }) =>
                    <>
                      <TextInput
                        {...field}
                        backgroundColor="#ffffff"
                        color="#2e2e2e"
                        maxLength={13}
                      />
                      {errors.name && <Text style={styles.errorText}>{errors.name.message}</Text>}
                    </>
                  }
                />
              </ViewTextInput>
              <ViewTextInput style={styles.halfWidth}>
                <Text>Tipo: <TooltipCustom text="Produto para venda única / Assinatura para venda recorrente" /></Text>
                <Controller
                  name="type"
                  control={control}
                  rules={getValidationRules('type', 'Tipo')}
                  render={({ field }) =>
                    <>
                      <Picker
                        {...field}
                        selectedValue={field.value}
                        style={{ height: 50, width: '100%', marginTop: "5px" }}
                      >
                        <Picker.Item label="ASSINATURA" value="plan" />
                        <Picker.Item label="PRODUTO" value="product" />
                      </Picker>
                      {errors.type && <Text style={styles.errorText}>{errors.type.message}</Text>}
                    </>
                  }
                />
              </ViewTextInput>
            </View>

            <ViewTextInput>
              <Text>Descrição: <TooltipCustom text="Descrição do produto/assinatura" /></Text>
              <Controller
                name="description"
                control={control}
                rules={getValidationRules('description', 'Descrição')}
                render={({ field }) =>
                  <>
                    <TextInput
                      {...field}
                      backgroundColor="#ffffff"
                      color="#2e2e2e"
                      maxLength={50}
                    />
                    {errors.description && <Text style={styles.errorText}>{errors.description.message}</Text>}
                  </>
                }
              />
            </ViewTextInput>
            <ViewTextInput>
              <Text>Dias de Validade após a Compra:<TooltipCustom height={86} text="ATENÇÃO: apenas adicione uma data de validade se esse produto for uma venda única (one-time purchase)" /></Text>
              <Controller
                name="expirationDate"
                control={control}
                rules={getValidationRules('expirationDate', 'Dias de Validade após a Compra')}
                render={({ field }) => <>
                  <TextInput
                    {...field}
                    backgroundColor="#ffffff"
                    color="#2e2e2e"
                    keyboardType='number'
                  />
                  {errors.expirationDate && <Text style={styles.errorText}>{errors.expirationDate.message}</Text>}
                </>
                }
              />
              <Text style={{ marginTop: "10px" }}>ATENÇÃO: apenas adicione uma data de validade se esse produto for uma venda única (one-time purchase)</Text>
            </ViewTextInput>
          </View>
        )}
        {/*TODO FIX-me makes no sense create a product already archived*/}
        {/*<ViewTextInput>*/}
        {/*  <Text>Arquivado:</Text>*/}
        {/*  <Controller*/}
        {/*    name="archived"*/}
        {/*    control={control}*/}
        {/*    render={({ field }) => (*/}
        {/*      <Switch*/}
        {/*        value={field.value}*/}
        {/*        onValueChange={(value) => field.onChange(value)}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  />*/}
        {/*</ViewTextInput>*/}

        <Text style={styles.subHeaderText}> Oferta</Text>
        <View style={styles.row}>
          <ViewTextInput style={styles.halfWidth}>
            <Text>Preço:<TooltipCustom text="Preço do produto em Reais." /></Text>
            <Controller
              name="price"
              control={control}
              rules={getValidationRules('price', 'Preço')}
              render={({ field }) =>
                <>
                  <CurrencyInput
                    value={field.value}
                    onChangeValue={(value) => field.onChange(value)}
                    prefix="R$"
                    delimiter=""
                    separator="."
                    precision={2}
                    minValue={0}
                    maxValue={99999.99}
                    style={[styles.halfWidth,
                    {
                      borderWidth: "1px",
                      borderRadius: "5px",
                      marginTop: "5px",
                      paddingLeft: "5px",
                    }]}
                  />
                  {errors.price && <Text style={styles.errorText}>{errors.price.message}</Text>}
                </>
              }
            />
          </ViewTextInput>

          <ViewTextInput style={styles.halfWidth}>
            <Text>Dias de teste grátis:<TooltipCustom text="(Opcional) Quantidade de dias em que o aluno terá acesso ao conteúdo antes que seja feita qualquer cobrança" /></Text>
            <Controller
              name="trialDays"
              control={control}
              rules={getValidationRules('trialDays', 'Dias de teste grátis')}
              render={({ field }) =>
                <>
                  <TextInput
                    {...field}
                    backgroundColor="#ffffff"
                    color="#2e2e2e"
                    keyboardType="numeric"
                  />
                  {errors.trialDays && <Text style={styles.errorText}>{errors.trialDays.message}</Text>}
                </>
              }
            />
          </ViewTextInput>
        </View>

        {watchType === 'plan' && (
          <View>
            <View style={styles.row}>

              <ViewTextInput style={styles.halfWidth}>
                <Text>Cobrar a cada: (meses)<TooltipCustom text="Determina a frequência de cobrança recorrente em meses" /></Text>
                <Controller
                  name="frequency"
                  control={control}
                  rules={getValidationRules('frequency', 'Frequência de cobrança em meses')}
                  render={({ field }) =>
                    <>
                      <TextInput
                        {...field}
                        backgroundColor="#ffffff"
                        color="#2e2e2e"
                        keyboardType="numeric"
                      />
                      {errors.frequency && <Text style={styles.errorText}>{errors.frequency.message}</Text>}
                    </>
                  }
                />
              </ViewTextInput>

              <ViewTextInput style={styles.halfWidth}>
                <Text>Ciclo máximo de cobranças:<TooltipCustom height={66} text="Determina o número máximo de cobranças, caso 0 assinatura será renovada indefinidamente qualquer outro valor cancela a assinatura após o número de ciclos escolhido" /></Text>
                <Controller
                  name="maxFrequency"
                  control={control}
                  render={({ field }) =>
                    <TextInput
                      {...field}
                      backgroundColor="#ffffff"
                      color="#2e2e2e"
                      keyboardType="numeric"
                    />
                  }
                />
              </ViewTextInput>
            </View>

            <ViewTextInput>
              <Text>Número máximo de parcelas:<TooltipCustom height={66} text="Número limite de parcelas, caso 12 o usuário podera parcelar até 12 vezes, caso 1 apenas pagamento a vista. Lembre-se que juros por parcela serão repassados para o aluno automaticamente" /></Text>
              <Controller
                name="paymentFractions"
                control={control}
                rules={getValidationRules('paymentFractions', 'Numero de Parcelas')}
                render={({ field }) =>
                  <>
                    <Picker
                      {...field}
                      selectedValue={field.value}
                      style={{ height: 50, marginTop: "5px" }}
                    >
                      {renderPaymentFractionItems()}
                    </Picker>
                    {errors.paymentFractions && <Text style={styles.errorText}>{errors.paymentFractions.message}</Text>}
                  </>
                }
              />
            </ViewTextInput>
          </View>
        )}

        <View style={{ marginTop: '1rem' }}>
          <ViewButton style={{ marginTop: '1rem' }}>
            <Button
              title="Salvar"
              isLoading={loadingSave}
              onPress={handleSubmit(onSubmit)}
              colorbutton={"#3FC380E6"}
            />
          </ViewButton>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modalView: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
    alignItems: 'center',
    width: screenWidth > 980 ? screenWidth * 0.6 : screenWidth * 0.9,
  },
  headerText: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
  },
  subHeaderText: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 20,
    marginBottom: 10,
    textAlign: 'center',
    borderBottomColor: "black"
  },
  errorText: {
    color: 'red',
    fontSize: 12,
    marginTop: 4,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  halfWidth: {
    flex: 1,
    marginHorizontal: 5,
  },
});

export default CreateProductGenericForm;